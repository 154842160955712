<template>
  <div>
    <search-batch-operation-record @submit="submitSearch" />

    <div class="iotplt-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <div slot="operation_no" slot-scope="operation_no, record">
        <router-link :to="{ name: 'batch_operation_record_info', params: { id: record.id }}">
          {{ operation_no }}
        </router-link>
      </div>

      <div slot="status" slot-scope="status, record">
        <a-tooltip v-if="record.failure_reason.length > 0" class="iotplt-cursor-pointer">
          <template slot="title">
            {{ record.failure_reason }}
          </template>
          {{ status }}
          <a-icon type="info-circle" />
        </a-tooltip>
        <span v-else>{{ status }}</span>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findBatchOperationRecords, exportBatchOperationRecords } from '@/api/batch_operation_record'
import Pagination from '@/components/Pagination'
import SearchBatchOperationRecord from '@/views/batch_operation_records/Search'
import { exportExcel } from '@/api/excel'

export default {
  name: 'BatchOperationRecordList',
  components: {
    SearchBatchOperationRecord,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {},
      columns: [
        {
          title: '批量操作批次',
          dataIndex: 'operation_no',
          fixed: 'left',
          scopedSlots: { customRender: 'operation_no' }
        },
        {
          title: '批量操作类型',
          fixed: 'left',
          dataIndex: 'operation_type',
          scopedSlots: { customRender: 'operation_type' }
        },
        {
          title: '卡号总数',
          dataIndex: 'operation_count'
        },
        {
          title: '操作成功数',
          dataIndex: 'success_count'
        },
        {
          title: '操作失败数',
          dataIndex: 'failure_count'
        },
        {
          title: '进度',
          dataIndex: 'task_progress'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作类型',
          dataIndex: 'operator_type'
        },
        {
          title: '操作人',
          dataIndex: 'operator_name'
        },
        {
          title: '操作时间',
          fixed: 'right',
          dataIndex: 'created_at'
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findBatchOperationRecords(this.query).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },

    exportData() {
      exportBatchOperationRecords(this.query).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
